import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import posterImg from '../imgs/tfk-cover.jpg';

import { LandingContainer } from '../styles/index.css';
import { GlobalStyle } from '../styles/common.css';

import MainNav from '../components/mainNav';

import Footer from '../components/footer';

function IndexPage({ data }) {
  return (
    <>
      <GlobalStyle />
      <LandingContainer>
        <main className="top" role="main">
          <Img className="cover" fluid={data.datoCmsIndex.cover.fluid} />
          <div className="main-info drop-down">
            <Img
              className="logo fade-in"
              fluid={data.datoCmsIndex.logo.fluid}
              alt="Logo"
            />

          </div>
        </main>
        <section className="details">
          <div>
            <h1>{data.datoCmsIndex.title}</h1>
            <div
              className="noset"
              dangerouslySetInnerHTML={{
                __html: data.datoCmsIndex.bodyNode.childMarkdownRemark.html
              }}
            />
            <p>
              <span className="announcement">
                {data.datoCmsIndex.announcement}
              </span>
            </p>
          </div>
        </section>

        <section className="watch">
          <div className="details-heading"><strong>CLICK TO WATCH:</strong></div>
          <div className="submit-details">

            <div className="tv-logo">
              <Link to="https://therokuchannel.roku.com/details/86c08261fc015a58866210bea63eb8f4/always-funny-videos"><img
                className=" fade-in"
                src={require('../imgs/tv_logos/AF_Website_Platform_Roku.png')}
                alt="Roku logo"
              />
              </Link>
            </div>

            <div className="tv-logo">
              <Link to="https://tubitv.com/live/653208/always-funny"><img
                className=" fade-in"
                src={require('../imgs/tv_logos/AF_Website_Platform_Tubi.png')}
                alt="Tubi logo"
              />
              </Link>
            </div>

            <div className="tv-logo">
              <Link to="https://www.cwtv.com/thecw/channels/?channel=always-funny"><img
                className=" fade-in"
                src={require('../imgs/tv_logos/cw_logo.png')}
                alt="The CW"
              />
              </Link>
            </div>

            <div className="tv-logo">
              <Link to="https://localnow.com/channels/afv-family"><img
                className=" fade-in"
                src={require('../imgs/tv_logos/AF_Website_Platform_LocalNow.png')}
                alt="Local Now logo"
              />
              </Link>
            </div>

            <div className="tv-logo">
              <Link to="https://watch.plex.tv/live-tv/channel/always-funny"><img
                className=" fade-in"
                src={require('../imgs/tv_logos/AF_Website_Platform_Plex.png')}
                alt="Plex logo"
              />
              </Link>
            </div>

          </div>
        </section>


        <section className="watch found">
          <div className="details-heading"><strong>ALSO FOUND ON:</strong></div>
          <div className="submit-details">

            <div className="tv-logo">
              <img
                className=" fade-in"
                src={require('../imgs/tv_logos/AF_Website_Platform_Samsung.png')}
                alt="Samsung TV Plus logo"
              />
            </div>

            <div className="tv-logo">
              <img
                className=" fade-in"
                src={require('../imgs/tv_logos/AF_Website_Platform_Freevee.png')}
                alt="Freevee logo"
              />
            </div>

            <div className="tv-logo">
              <img
                className=" fade-in"
                src={require('../imgs/tv_logos/AF_Website_Platform_Vizio.png')}
                alt="Vizio WatchFree logo"
              />
            </div>

            <div className="tv-logo">
              <img
                className=" fade-in"
                src={require('../imgs/tv_logos/fire_tv.png')}
                alt="FireTV logo"
              />
            </div>

            <div className="tv-logo">
              <img
                className=" fade-in"
                src={require('../imgs/tv_logos/tcl.png')}
                alt="TCL logo"
              />
            </div>

            <div className="tv-logo">
              <img
                className=" fade-in"
                src={require('../imgs/tv_logos/AF_Website_Platform_Sling.png')}
                alt="Sling logo"
              />
            </div>

          </div>

        </section>

        <section className="video">
          <video width="100%" controls poster={posterImg}>
            <source id="mp4" src="https://d20eudbgldb3n3.cloudfront.net/afvweb/General%20promo_1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </section>


        <Footer />
      </LandingContainer>
    </>
  );
}

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    datoCmsIndex {
      cover {
        url
        fluid(maxWidth: 3000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      logo {
        fluid(maxWidth: 350, imgixParams: { fm: "png", auto: "compress" }) {
          src
        }
      }
      launch
      title
      uploadDescriptionNode {
        childMarkdownRemark {
          html
        }
      }
      uploadWarning
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      announcement
    }
  }
`;
